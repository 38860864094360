













import Vue from "vue";
import * as auth from "@/service/user/user.service";

export default Vue.extend({
  computed: {
    fullname(): string {
      return auth.state.user?.firstName + " " + auth.state.user?.lastName;
    },
  },
});
